'use strict';
var wow = new WOW();
    wow.init();
$(document).ready(function(){
  numericCounter();
  window.onscroll = function() {
    numericCounter();
    numericCounterCompare();
  };

  var numericFlag = true;
  var numericCounter;
  function numericCounter() {
    var numericBlock = document.querySelector("#numeric");
	  if(!$("#numeric").length){
  		return false;
  	}
    if (window.pageYOffset + window.innerHeight > numericBlock.offsetTop + 30 && numericFlag) {
      numericFlag = false;
      $('.numeric-item_value:not(.inactive)').each(function(i,elem) {
        var numericStr = $(this).data("numeric");
        $({numberValue: 0}).animate({numberValue: deleteSpace(numericStr) }, {
          duration: 1500, 
          easing: "linear",
          
          step: function(val) {
          
            $(".numeric-item_value:not(.inactive)").eq(i).html(thousandSeparator(Math.ceil(val))); 
          }
        });
      })
    }
  }
  numericCounterCompare();
  $('.comparison-results').scroll(function() {
    var leftPositionScroll = $(this).scrollLeft();
    if(leftPositionScroll < 0){
      leftPositionScroll = 0;
    } else if (leftPositionScroll > ($('.communities-item--wrap').width() * $('.communities-item--wrap').length) - $('.comparison-results-inner').width()){
      leftPositionScroll =  Math.ceil(($('.communities-item--wrap').width() * $('.communities-item--wrap').length) - $('.comparison-results-inner').width());   
    }
    scrollCompareTitle(leftPositionScroll);
  });
  function scrollCompareTitle(param){
    $('.comparison-people-title').css('left', param+'px');
    $('.social-description-title').css('left', param+'px');
    $('.liner-comparison-title').css('left', param+'px');
    $('.pie-comparison-title').css('left', param+'px');
    $('.bar-comparison-title').css('left', param+'px');
    $('.doughnut-comparison-title').css('left', param+'px');
    $('.pie-legend-wrap .legend-inner').css('left', param+'px');
    $('.doughnut-legend-wrap .legend-inner').css('left', param+'px');
    if($(window).width() <= 767){
      $('.social-description-list').css('left', param+'px');
    } else {
      $('.social-description-list').css('left', 0);
    }
  }
    

  function numericCounterCompare() {
    if(!$(".comparison-numeric:not(.inactive)").length){
      return false;
    }
    var numericBlock = $(".comparison-numeric:not(.inactive)");
    numericBlock.each(function(i,elem) {
    if (window.pageYOffset + window.innerHeight > numericBlock.eq(i).offset().top + 30) {
      var numericStr = $(this).find('.comparison-people-value');
      $(this).addClass('inactive');
      numericStr.each(function(i,elem) {
        var numericStr = $(this).data("numeric");
        var $this = $(this);
        $({numberValue: 0}).animate({numberValue: deleteSpace(numericStr) }, {
          duration: 1500, 
          easing: "linear",
          
          step: function(val) {
          
            $this.text(thousandSeparator(Math.ceil(val))); 
          }
        });
      });
    }
  })
    
  }
  function deleteSpace(param){
    return Number(param.toString().replace(/\s/g, ''));
  }
  function thousandSeparator(param){
    return param.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  }
  
  if($('.owl-carousel').length){
    var $owl = $('.owl-carousel').owlCarousel({
      loop: false,
      items: 1,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: ["<i class='icon-carousel-left'></i>", "<i class='icon-carousel-right'></i>"],
      onInitialized: function() {
        if($(window).width() > 767){
          if($('.icon-carousel-right').length ){
            $('.icon-carousel-right').css('margin-left' , $('.owl-dots').innerWidth() + 'px');
          }
        }
      }
    });
  }

  $('.search-btn').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('open');
    $(this).siblings('input').trigger('focus');
  });

  function scrollComparison() {
    var scr = $('.comparison-results');
    scr.mousedown(function () {
    var startX = this.scrollLeft + event.pageX;
    scr.mousemove(function () {
      this.scrollLeft = startX - event.pageX;
        return false;
      });
    });
    $(window).mouseup(function () {
      scr.off("mousemove");
    });
  };
  if($('.comparison-results').length){
    scrollComparison();
  }

  //for modals
  function disable_scroll_mobile() {
    window.addEventListener('touchmove', wheel, { passive: false });
  }
  function enable_scroll_mobile() {
    window.removeEventListener('touchmove', wheel, { passive: false });
  }
  // var keys = [32, 33, 34, 35, 36, 37, 38, 39, 40];
  var keys = [33, 34, 35, 36];
  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
  }
  function keydown(e) {
    for (var i = keys.length; i--;) {
        if (e.keyCode === keys[i]) {
            preventDefault(e);
            return;
        }
    }
  }
  function wheel(e) {
    preventDefault(e);
  }
  function disable_scroll() {
    if (window.addEventListener) {
        window.addEventListener('DOMMouseScroll', wheel);
    }
    window.addEventListener('mousewheel', wheel, { passive: false });
    document.onkeydown = keydown;
    disable_scroll_mobile();
  }
  function enable_scroll() {
    if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', wheel);
    }
    window.removeEventListener('mousewheel', wheel, { passive: true });
    enable_scroll_mobile();
  }

  /*** iOS Body Scroll Lock with open menu/other modal blocks ***/

  var $docEl = $('body'),
    scrollTop;
  var overlayClose = function () {
    $.unlockBody();
  }
  var overlayOpen = function () {
    $.lockBody();
  }
  $.lockBody = function () {
    if (window.pageYOffset) {
        scrollTop = window.pageYOffset;
    }
    $docEl.css({
        height: "100%",
        overflow: "hidden"
    });
    $('html').css({
        height: "100%",
        overflow: "hidden"
    });
  }
  $.unlockBody = function () {
    $docEl.css({
        height: "",
        overflow: ""
    });
    $('html').css({
        height: "",
        overflow: ""
    });
    window.scrollTo(0, scrollTop);
    window.setTimeout(function () {
        scrollTop = null;
    }, 0);
  }


  /**
   * Open|Close popups
   */
  $('.wrap_container').on('click', '.open-popup', function (e) {
    e.preventDefault();
    if ($(this).hasClass('disabled')) return false;

    var $this = $(this);
    openPopups($this);
  });

  //Close this popup with button class
  $('.close_this_popup').on('click', function (e) {
    e.preventDefault();
    $(this).closest('.wrap_popup').find('.close').trigger('click');
  });

  function openPopups($this) {
    $('.overlay_page, #' + $this.attr('data-popup')).fadeIn(200);

    if (window.innerWidth < 1092) {
      overlayOpen();
    } else {
      disable_scroll();
    }

    //Set active to first input
    setTimeout(function () {
      $('#' + $this.attr('data-popup')).find('form input').first().focus();
    }, 250);
    calcSetTopHeight($('#' + $this.attr('data-popup')));
  }

  function calcSetTopHeight($el) {
    var width = $el.outerWidth() / 2,
        height = $el.outerHeight() / 2,
        windowH = $(window).height() / 2,
        windowW = $(window).width() / 2,
        top = windowH - height,
        left = windowW - width;

    if($(window).width() > 767) {
      $el.css('top', top + 'px');
      $el.css({
        'top': top + 'px',
        'left': left + 'px',
      });
    }
  }

  $('.wrap_popup').each(function () {
    var $el = $(this);
    calcSetTopHeight($el);
  });

  $('.wrap_popup .close, .overlay_page').on('click', function (e) {
    e.preventDefault();
    
    $('.overlay_page, .wrap_popup').fadeOut(200);

    if (window.innerWidth < 1092) {
      overlayClose();
    } else {
      enable_scroll();
    }
  });

  //Open menu
  var $btnOpen = $('.burger-btn'),
      $closeMenu = $('.overlay_page, .nav-side .close-btn'),
      $showMenuEl = $('.nav-side');

  $btnOpen.on('click', function (e) {
    e.preventDefault();
    $showMenuEl.addClass('open');
    $('.overlay_page').fadeIn(200);

    if (window.innerWidth < 1092) {
      overlayOpen();
    } else {
      disable_scroll();
    }
  });

  $closeMenu.on('click', function () {
    $showMenuEl.removeClass('open');
    $('.overlay_page').fadeOut(200);

    if (window.innerWidth < 1092) {
      overlayClose();
    } else {
      enable_scroll();
    }
  });
  $('.sing-in-form').each(function () {
      $(this).validate({
          highlight: function (element) {
              $(element).addClass("error").removeClass("success");
          },
          unhighlight: function (element) {
              $(element).removeClass("error").addClass("success");
          },
          //Send form
          submitHandler: function (form) {
              
          }
      });
  });
  
    // Tabs
    let $tabsWrap = $('.tab_area');
    if($tabsWrap.length) {
      $tabsWrap.on('click', '.tab_controls a:not(.active)', function (e) {
        e.preventDefault();
        let $this = $(this);
        $('.tab_controls').find('a').removeClass('active');
        $('.tab_controls').find('[data-id="'+$this.attr('data-id')+'"]').addClass('active');

        let $content = $this.closest('.tab_area');
        $content.find('.tab_content').children('div:not(#'+ $this.attr('data-id') +')').fadeOut(0, function () {
          $content.find('#' + $this.attr('data-id')).fadeIn(0);
        });
      });
    }
	// фильтр громад по регионам
  if($('select').length){
	  $('.selected-list select').select2();
    $('.selected-list.selected-list-static select').select2({dropdownCssClass: 'dropdownNews'});
	}
   // сравнение громад 
	  $('.communities-list').on('click', '.compare-block', function(e) {
      e.preventDefault();
	    var $dataCommunities = $(this).parents('.communities-item').data('communities');
	    var $communitiesTitle = $(this).parents('.communities-item').find('.communities-title span').text();
	    var compareItem = '<div class="compare-form-item" data-communities="'+ $dataCommunities +'"><span>'+ $communitiesTitle +'</span><a href="javascript:void(0);" class="compare-delete"></a><input type="text" name="id[]" value="'+$dataCommunities+'"></div>';
	    if($(this).parents('.communities-item').hasClass('compare-active')){
	      $('.compare-form-item[data-communities='+$dataCommunities+']').remove();
	      $(this).parents('.communities-item').removeClass('compare-active');
	    } else { 
	      $('.compare-form-list').append(compareItem);
	      $(this).parents('.communities-item').addClass('compare-active');
	    };
      addTextEmptyCompare();
	  });
	  $('.compare-form-list').on('click', '.compare-delete', function(e) {
	      var $deleteCommunities = $(this).parents('.compare-form-item').data('communities');
	      $(this).parents('.compare-form-item').remove();
	      $('.communities-item[data-communities='+$deleteCommunities+']').removeClass('compare-active');
		    addTextEmptyCompare();
        if($('.comparison-results').length){
          if($('.comparison-list .communities-item').length == 1){
            $('.comparison-results-inner').remove();
          } else {
            $('*[data-communities='+$deleteCommunities+']').remove();
          }
        }
    });
    function addTextEmptyCompare(){
      if(!$('.compare-form-list .compare-form-item').length){
        $('.compare-form-empty').css('display', 'block');
        $('.compare-button').prop("disabled", true);
      } else {
        $('.compare-form-empty').css('display', 'none');
        $('.compare-button').prop("disabled", false);
      }
    };
    
    // for second menu
    
    /**
     * Hover duration
     */
    function hoverDelivery(el_1, el_2) {
      var $classFirst = el_1,
          $classSecond = el_2,
          interval = 100,
          timer = null,
          timerDelayHover = null;

      $classFirst.hover(function () {
          if ($(this).closest('.hover_delivery').hasClass('empty')) return false;

          clearTimeout(timer);
          var $this = $(this);

          //Need for set delay before show these blocks
          timerDelayHover = setTimeout(function () {
            $classFirst.each(function(){
              $(this).removeClass('open');
              $(this).parent().find($classSecond).css({
                  'visibility': 'hidden',
                  'opacity': '0'
              });
            });
              $this.addClass('open');

              $('.overlay_page').fadeIn(200);

              $this.parent().find($classSecond).css({
                  'visibility': 'visible',
                  'opacity': '1'
              });
              //When we need add class to top parent
              if ($this.attr('data-parent')) {
                  $this.closest('.' + $this.attr('data-parent')).addClass('open');
              }
          }, 200);
      }, function () {
          clearTimeout(timerDelayHover);
          var $this = $(this);
          timer = setTimeout(function () {
              closeBlur($this, $this.parent().find($classSecond));
          }, interval)
      });

      $classSecond.hover(function () {
          clearTimeout(timer);
      }, function () {
          var $this = $(this);
          timer = setTimeout(function () {
              closeBlur($this.parent().find($classFirst), $this);
          }, interval)
      });

      function closeBlur($class_1, $class_2) {
          $class_1.removeClass('open');
          $class_2.css({
              'visibility': 'hidden',
              'opacity': '0'
          });
          $('.hover_class1').each(function(){
            $(this).removeClass('open');
            $(this).parent().find($classSecond).css({
                'visibility': 'hidden',
                'opacity': '0'
            });
          });
          //When we need add class to top parent
          if ($class_1.attr('data-parent')) {
              $class_1.closest('.' + $class_1.attr('data-parent')).removeClass('open');
          }
          if(!$('.hover_class').hasClass('open')){
            $('.overlay_page').fadeOut(0);
          }
      }
  }
  function clickDelivery(el_1, el_2) {
    var $classFirst = el_1,
        $classSecond = el_2;

    $classFirst.click(function(e){
      e.preventDefault();
      var $this = $(this);

      if(!$this.hasClass('open')){
        if($this.hasClass('hover_class')){
          $('.hover_class').each(function(){
            $(this).removeClass('open');
            $(this).parent().find('.hover_css').css({
              'visibility': 'hidden',
              'opacity': '0'
            });
          });
          $('.hover_class1').each(function(){
            $(this).removeClass('open');
            $(this).parent().find('.hover_css1').css({
              'visibility': 'hidden',
              'opacity': '0'
            });
          });
        }

        $classFirst.each(function(){
          $(this).removeClass('open');
          $(this).parent().find($classSecond).css({
            'visibility': 'hidden',
            'opacity': '0'
          });
        });

        $this.addClass('open');
        $this.parent().find($classSecond).css({
          'visibility': 'visible',
          'opacity': '1'
        });
      } else {
        $('.hover_class').each(function(){
          $(this).removeClass('open');
          $(this).parent().find('.hover_css').css({
            'visibility': 'hidden',
            'opacity': '0'
          });
        });
        $('.hover_class1').each(function(){
          $(this).removeClass('open');
          $(this).parent().find('.hover_css1').css({
            'visibility': 'hidden',
            'opacity': '0'
          });
        });
      }
    });
  }

  if($(window).innerWidth() > 767){
    var $hDelivery = $('.hover_delivery');
    if ($hDelivery.length) {
        $hDelivery.each(function () {
            var el_1 = $(this).find('.hover_class'),
                el_2 = $(this).find('.hover_css');

            hoverDelivery(el_1, el_2);
            
            var submenuDistance = el_1.parent().find(el_2).innerWidth() + (el_1.parent().find(el_2).offset() ? el_1.parent().find(el_2).offset().left : 0);
            if (submenuDistance > $(window).innerWidth()) {
              el_1.parent('li').addClass('flex-end');
            }
        });
    }
    var $hDelivery = $('.big_menu-items');
    if ($hDelivery.length) {
        $hDelivery.each(function () {
            var el_1 = $(this).find('.hover_class1'),
                el_2 = $(this).find('.hover_css1');

            hoverDelivery(el_1, el_2);
            
            var submenuDistance = el_1.parent().find(el_2).innerWidth() + (el_1.parent().find(el_2).offset() ? el_1.parent().find(el_2).offset().left : 0);
            if (submenuDistance > $(window).innerWidth()) {
              el_1.parent('li').addClass('flex-end');
            }
        });
    }
  } else {
    var $hDelivery = $('.hover_delivery');
    if ($hDelivery.length) {
        $hDelivery.each(function () {
            var el_1 = $(this).find('.hover_class'),
                el_2 = $(this).find('.hover_css');

            clickDelivery(el_1, el_2);
            
            var submenuDistance = el_1.parent().find(el_2).innerWidth() + (el_1.parent().find(el_2).offset() ? el_1.parent().find(el_2).offset().left : 0);
            if (submenuDistance > $(window).innerWidth()) {
              el_1.parent('li').addClass('flex-end');
            }
        });
        
    }
    var $hDelivery = $('.big_menu-items');
    if ($hDelivery.length) {
        $hDelivery.each(function () {
            var el_1 = $(this).find('.hover_class1'),
                el_2 = $(this).find('.hover_css1');

            clickDelivery(el_1, el_2);
            
            var submenuDistance = el_1.parent().find(el_2).innerWidth() + (el_1.parent().find(el_2).offset() ? el_1.parent().find(el_2).offset().left : 0);
            if (submenuDistance > $(window).innerWidth()) {
              el_1.parent('li').addClass('flex-end');
            }
        });
    }
  }

  //filters results 
    $('.filter-tabs-list').on('click', 'button', function(e) {
      var $deleteCommunities = $(this).parents('.compare-form-item').data('communities');
      $(this).parents('.compare-form-item').remove();
      $('.communities-item[data-communities='+$deleteCommunities+']').removeClass('compare-active');
    });
    $('.filter-tabs-list').on('click', 'a:not(.active)', function(e) {
      e.preventDefault();
      $(this).addClass('active').siblings().removeClass('active')
      $('.results-search-list').find('div.results-search-item').removeClass('active').eq($(this).index()).addClass('active');
    });
  // people list border 
  // необходимо вызывать функцию при пагинации 
  if($('.people-list').length){
    defineBorderDraw();
  }
  function defineBorderDraw(){
    $('.people-item').each(function () {
      var namePeople =  $(this).find('.people-item_name span');
      var namePeopleWidth =  namePeople.innerWidth();
      var positionPeople =  $(this).find('.people-item_position span');
      var positionPeopleWidth = positionPeople.innerWidth();

      if(namePeopleWidth > positionPeopleWidth){
        namePeople.addClass('top-more');
      } else {
        positionPeople.addClass('bottom-more');
      }
    });
  };

  //gallery
  if($("#gallery").length){
    $("#gallery").unitegallery({
        tiles_type: "justified",
        tiles_justified_space_between: $(window).innerWidth() > 767 ? 32 : 8,
    });
  }

    $('.js-select-region').on('change', function () {
        var url = $(this).attr('data-action');
        var region_id = $(this).val();
        location.href =  url + '?region_id=' + region_id;
    });
    $('.js-select-district').on('change', function () {
        var url = $(this).attr('data-action');
        var region_id = $('.js-select-region').val();
        var district_id = $(this).val();
        location.href =  url + '?region_id=' + region_id + '&district_id=' + district_id;
    });

  $('.js-select-community-news').on('change', function () {
    var url = $(this).attr('data-action');
    var community_id = $(this).val();
    if (community_id != 0) {
      location.href = url + '?community=' + community_id;
    } else {
      location.href = url;
    }
  });
});

             
             